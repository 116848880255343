::-webkit-scrollbar {
    width: 0px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.body {
    font-family: 'Montserrat' !important;
}

.body-board-view {
    height: 100vh;
    background-size: cover;
    overflow: auto;
    background-image: url('../../public/img/background.jpg');
}

.no-scroll {
    scrollbar-width: none;
}


/* _lg */
@media only screen and (min-width: 1440px) {
    .harry-letter {
        line-height: 3.5rem !important;
    }

    .harry-small-letter {
        line-height: 2rem !important;
    }
}

/* _md */
@media only screen and (min-width: 1020px) {
    .harry-letter {
        line-height: 3rem !important;
    }
}

/* _sm */
@media only screen and (max-width: 840px) {

}

/* _mobile */
@media only screen and (min-width: 500px) {

}
